<template>
  <div class="chart-container">
    <div>
      <span class="text-bonus">Ваш бонус</span>
      <span class="discount"></span>
      <span  :class="{'min-cashback': cashback < 10}"  class="text-cashback">{{ cashback }}%</span>
    </div>
    <canvas :class="chartClass"></canvas>
    <div
        v-if="tooltip.visible"
        class="tooltip-container"
        :style="{ top: tooltip.y + 'px', left: tooltip.x + 'px' }"
    >
      Продажи: {{ sold }}
    </div>
  </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
import { showSuccessNotification } from '@/services/notifications'

export default {
  name: 'CurrentMonthChart',
  props: {
    sold: {
      type: Number,
      required: true,
      default: 21000
    },
    threshold: {
      type: Number,
      required: true,
      default: 20000
    },
    total: {
      type: Number,
      required: true,
      default: 150000
    },
    cashback: {
      type: Number,
      required: false,
      default: 20
    },
    title: {
      type: String,
      required: false,
      default: 'Текущий месяц'
    },
    chartClass: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tooltip: {
        visible: false,
        x: 0,
        y: 0
      }
    }
  },
  mounted() {
    this.renderChart()
  },
  methods: {
    renderChart() {
      const sectionColors = this.calculateSections(
          this.sold,
          this.threshold,
          this.cashback
      )
      const ctx = document
          .querySelector(`.${this.chartClass}`)
          .getContext('2d')
      const config = {
        type: 'doughnut',
        data: {
          datasets: [
            {
              data: Array(20).fill(5),
              backgroundColor: sectionColors,
              borderWidth: 1,
              borderColor: '#fff'
            }
          ]
        },
        options: {
          animation: {
            duration: 0
          },
          cutout: '70%',
          plugins: {
            tooltip: {
              enabled: false
            },
            legend: {
              display: false
            }
          }
        }
      }
      // eslint-disable-next-line no-new
      new Chart(ctx, config)
    },
    calculateSections(sold, threshold, cashback) {
      if (cashback < 20) {
        const sectionColors = []
        const lightGreyCount = 20

        const addColors = (color, count) => {
          sectionColors.push(...Array(count).fill(color))
        }
        if (sold <= 10_000) {
          addColors('red', 1)
          addColors('lightgrey', 19)
        } else if (sold <= 20_000) {
          addColors('red', 2)
          addColors('lightgrey', 18)
        } else {
          addColors('red', 2)
          const greenSections = Math.min(Math.ceil((sold - 20_000) / 10_000), lightGreyCount - 2)
          addColors('green', greenSections)
          addColors('lightgrey', lightGreyCount - sectionColors.length)

          if (sold > 200_000) {
            addColors('green', lightGreyCount - sectionColors.length)
          }
        }

        return sectionColors
      } else {
        const sectionColors = []
        const addColors = (color, count) => sectionColors.push(...Array(count).fill(color))

        if (sold <= 10_000) { addColors('red', 1); addColors('lightgrey', 19) } else if (sold <= 20_000) { addColors('red', 2); addColors('lightgrey', 18) } else if (sold <= 150_000) {
          addColors('red', 2)
          addColors('#ffa500b3', Math.ceil((sold - 20_000) / 10_000))
          addColors('lightgrey', 20 - 2 - Math.ceil((sold - 20_000) / 10_000))
        } else if (sold <= 200_000) {
          addColors('red', 2)
          addColors('#ffa500b3', 13)
          addColors('green', Math.ceil((sold - 150_000) / 10_000))
          addColors('lightgrey', 7 - Math.ceil((sold - 150_000) / 10_000))
        } else { addColors('red', 2); addColors('#ffa500b3', 13); addColors('green', 5) }

        return sectionColors
      }
    }
  }
}
</script>

<style scoped>
.chart-container {
  left: 33px;
  position: relative;
  width: 180px;
  height: 180px;
  @media screen and (max-width: 900px) {
    left: 0px;
  }
}

.title-container {
  font-size: 15px;
  color: #000000;
  margin: 0;
  text-align: center;
  padding-bottom: 5px;
  font-weight: bold;
  margin-bottom: 5px;
}

.discount {
  font-weight: 500;
  position: absolute;
  display: flex;
  left: 1.53em;
  top: 1.53em;
  font-size: 25px;
  border: 1px solid grey;
  width: 105px;
  border-radius: 50%;
  height: 105px;
  align-items: center;
  justify-content: center;
  border-style: dashed;
}

.tooltip-container {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px;
  border-radius: 3px;
  pointer-events: none;
  font-size: 12px;
  transform: translate(-50%, -100%);
  white-space: nowrap;
}

.text-bonus {
  display: flex;
  position: absolute;
  top: 58px;
  left: 57px;
  font-weight: 400;
  font-size: 14px;
}

.text-cashback {
  display: flex;
  position: absolute;
  top: 72px;
  left: 69px;
  font-weight: 600;
  font-size: 25px;
}
.min-cashback{
  left: 75px;
}
</style>
