<template>
  <div class="doctor-bonus-graph">
    <div class="container-graph row">
      <div>
        <p class="title-container">Текущий месяц</p>
      </div>
      <div class="col-xs-6 col-md-6 current-month-graph">
        <CurrentMonthChart
            v-if="props.data?.currentMonth?.threshold"
            class="col-9"
            :title="'Текущий месяц'"
            :sold="props.data?.currentMonth?.sales"
            :threshold="props.data?.currentMonth.threshold"
            :total="150000"
            :cashback="props.data?.cacheBack"
            :chart-class="mainChartTitle"
        />
      </div>
      <div class="col-12 col-sm-12 col-md-4 container-price">
        <span class="size-lable">Цель, руб</span>
        <span class="size-price"
        ><span class="dop-price">{{
            props.data?.cacheBack < 20 ? '20 000' : '150 000'
          }}</span></span
        >
        <span class="size-lable">Факт, руб</span>
        <span class="size-price"
        ><span class="dop-price">{{
            formatNumber(props.data?.currentMonth?.sales)
          }}</span></span
        >

        <span class="size-lable">Баллов к выплате, руб</span>
        <span class="size-price"
        ><span class="dop-price">{{
            formatNumber(props.data?.bonus)
          }}</span></span
        >
      </div>
    </div>
    <div class="col-12 text-center mb-0 dinamic-month mt-4">
      Динамика предыдущих месяцев
    </div>
    <div v-if="props.data?.beforeMonths" class="month-graphics">
      <div class="month-graphics__item">
        <BonusGraph
            :params="props.data?.beforeMonths[0]"
            :full="true"
            :cashback="props.data?.cacheBack"
        ></BonusGraph>
      </div>
      <div class="month-graphics__item">
        <BonusGraph
            :params="props.data?.beforeMonths[1]"
            :full="true"
            :cashback="props.data?.cacheBack"
        ></BonusGraph>
      </div>
      <div class="month-graphics__item">
        <BonusGraph
            :params="props.data?.beforeMonths[2]"
            :full="true"
            :cashback="props.data?.cacheBack"
        ></BonusGraph>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import BonusGraph from '@/components/ui/BonusGraph.vue'
import CurrentMonthChart from '@/components/ui/СurrentMonthChart.vue'

const props = defineProps(['data', 'mainChartTitle'])

const formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
})
const formatNumber = (num) => {
  if (num === 0) {
    return 0
  }
  if (num) {
    return num.toLocaleString('ru-RU')
  }
}
</script>

<style lang="scss" scoped>
.doctor-bonus-graph {
  width: 100%;
  height: auto;

  &__title {
    color: #333;
    font-size: 14px;
  }

  .month-graphics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin: 10px auto 0;
    @media screen and (max-width: 575px) {
      margin: 0 auto 0;
    }
    .month-graphics__item {
      width: 100%;
      @media screen and (max-width: 1000px) {
        margin-bottom: 10px;
      }
    }

    .month-graphics__item:nth-child(2) {
      margin: 0 10px;
      @media screen and (max-width: 1000px) {
        margin: 0 0 10px 0;
      }
    }

    @media screen and (max-width: 1000px) {
      display: block;
    }
  }

  .bonus-graph-text {
    padding-top: 15px;
    font-size: 14px;
    color: #333;
  }
}
.container-graph {
  display: flex;
  margin-bottom: 40px;
  @media screen and (max-width: 575px) {
    margin-bottom: 10px;
  }
}

.container-lable {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.size-lable {
  color: #00c266;
  width: 200px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  @media screen and (max-width: 900px) {
    font-size: 14px;
    width: 100%;
    justify-content: center;
  }
  @media (min-width: 576px) and (max-width: 1220px) {
    font-size: larger;
    width: 100%;
  }
}
.size-price {
  width: 210px;
  font-size: xx-large;
  font-weight: 700;

  @media screen and (max-width: 575px) {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    width: 100%;
    justify-content: center;
  }
  @media (min-width: 575px) and (max-width: 1220px) {
    box-sizing: content-box;
    /* display: flex; */
    /* justify-content: flex-end; */
    font-size: x-large;
    width: 100%;
  }
}
.dop-price {
  width: 100px;
  text-align: center;
  margin-bottom: 5px;
}
.container-price {
  font-size: 13px;
  left: 95px;
  position: relative;
  line-height: 25px;
  @media screen and (max-width: 900px) {
    line-height: normal;
    margin-top: 2rem !important;
    left: 0;
    text-align: center;
  }
  @media screen and (max-width: 575px) {
    margin-top: 3rem !important;
  }
}
.dinamic-month {
  font-size: 14px;
  color: #000000;
  margin: 0;
  padding-left: 10px;
  text-align: center;
  padding-bottom: 5px;
  font-weight: bold;
  margin-bottom: 5px;
}
.current-month-graph {
  text-align: -webkit-right;
  @media screen and (max-width: 575px) {
    text-align: -webkit-center;
  }
  @media screen and (min-width: 575px) and (max-width: 900px) {
    text-align: -webkit-center;
  }
}
.title-container {
  font-size: 14px;
  color: #000000;
  margin: 0;
  text-align: center;
  padding-bottom: 5px;
  font-weight: bold;
  margin-bottom: 5px;
  @media screen and (min-width: 900px) {
    position: relative;
    left: 35px;
  }
}
</style>
