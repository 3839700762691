<template>
  <div class="row d-none d-sm-flex mx-3 mb-4 mt-5 justify-content-center">
    <div class="col-12 col-sm-5 align-items-center justify-content-center">
      <h2 class="text-uppercase h4 m-0"><i class="bi bi-person-vcard fs-4 me-2"/>Ваши контакты</h2>
    </div>
    <div class="col-12 col-sm-5 align-items-center justify-content-center justify-content-center">
      <h2 class="text-uppercase h4 m-0"><i class="bi bi-person-gear fs-4 me-2"/>Контакты менеджера</h2>
    </div>
  </div>
  <div class="row mx-0 mx-sm-3 justify-content-center">
    <div class="col-12 col-sm-5 mt-2">
      <div
          class="d-flex d-sm-none col-12 col-sm-5 mb-2 align-items-center justify-content-center justify-content-center">
        <h2 class="text-uppercase h4 m-0"><i class="bi bi-person-gear fs-4 me-2"/>Профиль</h2>
      </div>
        <ul class="list-group list-group-main-style">
          <li class="list-group-item"><b>id: </b> {{ profileStore.id }}</li>
          <li v-if="authStore.isDoctor" class="list-group-item"><b>Должность: </b>Врач</li>
          <li class="list-group-item"><b>Фамилия: </b>{{ profileStore.last_name }}</li>
          <li class="list-group-item"><b>Имя: </b> {{ profileStore.first_name }}</li>
          <li class="list-group-item"><b>Отчество: </b>{{ profileStore.middle_name }}</li>
          <li class="list-group-item"><b>Почта: </b>{{ profileStore.email }}</li>
          <li class="list-group-item"><b>Телефон: </b>{{ profileStore.phone }}</li>
        </ul>
    </div>
    <div class="col-12 col-sm-5 mt-3 mt-sm-0">
      <div
          class="d-flex d-sm-none col-12 col-sm-5 mb-2 align-items-center justify-content-center justify-content-center">
        <h2 class="text-uppercase h4 m-0"><i class="bi bi-person-gear fs-4 me-2"/>Контакты менеджера</h2>
      </div>
        <ul class="list-group list-group-main-style" v-if="manager">
          <li class="list-group-item"><b>Фамилия:</b> {{ manager.last_name }}</li>
          <li class="list-group-item"><b>Имя:</b> {{ manager.first_name }}</li>
          <li class="list-group-item"><b>Отчество:</b> {{ manager.middle_name }}</li>
          <li class="list-group-item"><b>Телефон:</b> {{ manager.phone }}</li>
          <li class="list-group-item"><b>Почта:</b> {{ manager.email }}</li>
        </ul>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center mt-3">
    <h2 class="text-uppercase h4 m-0"><i class="bi bi-person-gear fs-4 me-2"/>Моя статистика</h2>
  </div>
  <div  class="row mx-0 mx-sm-3 mt-4 container-graph" v-if="profileStore.doctorBonus">
    <div class="mt-md-5 mt-2 doctor-bonus">
      <div>
        <LoadingSpinner v-if="profileStore.isLoading" wrapper-style="display: flex;
    position: static;
    height: 50px;" :showSpinner="true"/>
      </div>
      <div class="graph-bonus">
        <div v-if="profileStore.doctorBonus && Object.keys(profileStore.doctorBonus).length && !profileStore.isLoading  ">
          <DoctorBonusGraph :main-chart-title="`mainChart_${manager.id}`" :data="profileStore.doctorBonus"></DoctorBonusGraph>
        </div>
      </div>
    </div>
  </div>
  <div class="message-container empty" v-else>
    <p>Данные по бонусам формируются...</p>
  </div>

</template>

<script setup>
import { useProfileStore } from '@/stores/ProfileStore'
import { onMounted } from 'vue'
import { useAuthStore } from '@/stores/AuthStore'
import ProgressBar from '../ui/ProgressBar.vue'
import DoctorBonusGraph from '@/components/ui/DoctorBonusGraph.vue'
import LoadingSpinner from '@/components/Global/LoadingSpinner.vue'

const authStore = useAuthStore()
const { manager } = authStore.userProfile
const profileStore = useProfileStore()

onMounted(() => profileStore.getProfileData())
</script>

<style scoped lang="scss">
.ps {
  min-height: fit-content;
  max-height: calc(80vh - 104px);
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
}

.container-total {
  margin-left: 10%;
  margin-top: 25px;
  margin-bottom: 25px;
}

.message-container {
  margin-top: 7%;
  display: flex;
  justify-content: center;
  color: #7d7b7b;
  font-size: 14px;

  &.empty {
    margin: 25px 0 15px 0;
  }
  @media screen and (max-width: 575px) {
    text-align: center;
    margin-top: 30%;

    &.empty {
      margin: 25px 0 15px 0;
    }
  }
}
.container-graph{
  min-height: fit-content;
  box-shadow: 0 0 0 1px var(--bs-border-color), 2px 2px 12px 0 rgba(34, 60, 80, 0.15);
  border-radius: var(--bs-border-radius);
  position: relative;
  bottom: 10px;
  @media screen and (min-width: 900px) {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
}
.graph-bonus{
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
}
.doctor-bonus{
  min-height:  200px
}

</style>
